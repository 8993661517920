<template>
  <div class="Integral_wrapper">
    <el-tabs v-model="tabName" @tab-click="handleClick">
      <el-tab-pane label="导购员积分" name="1">
        <div class="select_wrap">
          <el-select v-model="ScoreInfo.type" placeholder="奖励类型" class="mr10" clearable>
            <el-option
              v-for="item in bonusTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入手机号搜索"
            v-model="ScoreInfo.mobile"
            style="width:200px"
          ></el-input>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入姓名搜索"
            v-model="ScoreInfo.name"
            style="width:200px"
          ></el-input>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入用户ID"
            v-model="ScoreInfo.userId"
            style="width:200px"
          ></el-input>
          <el-date-picker
            v-model="ScoreInfo.dateRange"
            class="ml10"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="执行开始时间"
            style="width:400px"
            end-placeholder="执行结束时间">
          </el-date-picker>
          <el-select v-model="ScoreInfo.userStatus" clearable placeholder="请选择导购员状态" class="ml10">
            <el-option
              v-for="item in errorOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
          <el-button type="primary" class="ml10" @click="exportScoreData">导出表格</el-button>
        </div>
        <el-table class="secondsKill_table" :data="ScoreList" border stripe style="width: 100%">
          <el-table-column prop="userId" label="用户ID"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="mobile" label="手机号码"></el-table-column>
          <el-table-column prop="brandName" label="所属品牌"></el-table-column>
          <el-table-column prop="shopName" label="所属门店"></el-table-column>
          <el-table-column prop="createTime" label="注册时间"></el-table-column>
          <el-table-column prop="bonusType" label="奖励类型">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.bonusType=='view'">浏览</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='fission'">裂变</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='enrollment'">报名</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='card'">售券</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='live_view'">进直播间客户</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='live_order_cus'">直播下订</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='live_self_order'">直播自签单</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='live_other_order'">直播贡献单</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='live_self_order_change'">直播自签转单</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='live_other_order_change'">直播贡献转单</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='process_self_order'">过程自签单</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='process_devote_order'">过程贡献单</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='seckill_user'">秒杀客户</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='thumb'">集赞客户</el-tag>
              <el-tag type="primary" v-if="scope.row.bonusType=='appointment'">直播预约</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="scoreAmount" label="奖励积分"></el-table-column>
          <el-table-column prop="userStatus" align="center" label="导购员状态">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.userStatus=='normal'">正常</el-tag>
              <el-tag type="warning" v-if="scope.row.userStatus=='second'">超出每秒预设的阈值</el-tag>
              <el-tag type="warning" v-if="scope.row.userStatus=='hour'">超出每小时预设的阈值</el-tag>
              <el-tag type="warning" v-if="scope.row.userStatus=='day'">超出每天预设的阈值</el-tag>
              <el-tag type="warning" v-if="scope.row.userStatus=='qcloud'">腾讯云判断异常</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="implementTime" label="执行时间"></el-table-column>
          <el-table-column prop="scoreAvailableAmount" label="积分总数"></el-table-column>
        </el-table>
        <div class="tab-page">
          <div></div>
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="ScoreInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="ScoreTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="品牌积分" name="2">
        <div class="select_wrap">
          <!-- <el-select v-model="ScoreBrandInfo.type" placeholder="奖励类型" class="mr10" clearable>
            <el-option
              v-for="item in bonusTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>-->
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入品牌名称"
            v-model="ScoreBrandInfo.name"
            style="width:200px"
          ></el-input>
          <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage2">搜索</el-button>
          <el-button type="primary" class="ml10" @click="getExportBrandScoreList">导出表格</el-button>
        </div>
        <el-table
          class="secondsKill_table"
          :data="ScoreBrandList"
          border
          stripe
          style="width: 100%"
        >
          <el-table-column prop="brandId" label="品牌ID"></el-table-column>
          <el-table-column prop="name" label="品牌名称"></el-table-column>
          <el-table-column prop="scoreAmount" label="积分奖惩"></el-table-column>
          <el-table-column prop="remark" label="奖惩原因"></el-table-column>
          <el-table-column prop="implementTime" label="执行时间"></el-table-column>
          <el-table-column prop="scoreAvailableAmount" label="剩余积分数"></el-table-column>
        </el-table>
        <div class="tab-page">
          <div></div>
          <el-pagination
            background
            @current-change="handleCurrentChange2"
            :current-page="ScoreBrandInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="ScoreBrandTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 主页面 -->
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
export default {
  name: "Integral", //
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      nodata: "",
      nobool: false,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],

      ScoreInfo: {
        actId: 0,
        mobile: "",
        name: "",
        page: 1,
        size: 10,
        type: "",
        userId: "",
        userStatus: "",
      },
      ScoreList: [],
      ScoreTotal: 0,
      ScoreBrandInfo: {
        actId: 0,
        name: "",
        page: 1,
        size: 10,
        type: "",
      },
      ScoreBrandList: [],
      ScoreBrandTotal: 0,
      errorOptions: [
        { value: "normal", label: "正常" },
        { value: "second", label: "超出每秒预设的阈值" },
        { value: "hour", label: "超出每小时预设的阈值" },
        { value: "day", label: "超出每天预设的阈值" },
        { value: "qcloud", label: "腾讯云判断异常" },
      ],
      bonusTypeOptions: [
        { value: "view", label: "浏览" },
        { value: "fission", label: "裂变" },
        { value: "enrollment", label: "报名" },
        { value: "card", label: "售券" },
        { value: "appointment", label: "直播预约" },
        { value: "live_view", label: "进直播间客户" },
        { value: "live_order_cus", label: "直播下订" },
        { value: "live_self_order", label: "直播自签单" },
        { value: "live_other_order", label: "直播贡献单" },
        { value: "live_self_order_change", label: "直播自签转单" },
        { value: "live_other_order_change", label: "直播贡献转单" },
        { value: "process_self_order", label: "过程自签单" },
        { value: "process_devote_order", label: "过程贡献单" },
        { value: "seckill_user", label: "秒杀客户" },
        { value: "thumb", label: "集赞客户" },
      ],
      tabName: "1",
    };
  },
  computed: {},
  watch: {},
  methods: {
    //切换tab方法
    handleClick() {
      if (this.tabName == "1") {
        this.ScoreInfo.page = 1;
        this.getQueryScoreList();
      } else if (this.tabName == "2") {
        this.ScoreBrandInfo.page = 1;
        this.getQueryBrandScoreList();
      }
    },
    // 导购员奖励积分列表页码请求
    handleCurrentChange(val) {
      this.ScoreInfo.page = val;
      this.getQueryScoreList();
    },
    searchPage() {
      this.ScoreInfo.page = 1;
      this.getQueryScoreList();
    },
    // 品牌奖励积分列表页码请求
    handleCurrentChange2(val) {
      this.ScoreBrandInfo.page = val;
      this.getQueryBrandScoreList();
    },
    searchPage2() {
      this.ScoreBrandInfo.page = 1;
      this.getQueryBrandScoreList();
    },
    // 查询导购员奖励积分列表
    async getQueryScoreList() {
      let params = {
        ...this.ScoreInfo
      }
      if (params.dateRange) {
        params.startTime = params.dateRange[0]
        params.endTime = params.dateRange[1]
      } else {
        params.startTime = null
        params.endTime = null
      }
      delete params.dateRange
      try {
        params.actId = this.activityID;
        const {data: { list, total }} = await GuideAjax.getQueryScoreList(params);
        this.ScoreList = list;
        this.ScoreTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出导购员奖励积分列表
    async exportScoreData() {
      let params = {
        ...this.ScoreInfo
      }
      params.actId = Number(this.activityID)||0;
      if (params.dateRange) {
        params.startTime = params.dateRange[0]
        params.endTime = params.dateRange[1]
      } else {
        params.startTime = null
        params.endTime = null
      }
      delete params.dateRange
      try {
        const data = await GuideAjax.getExportScoreList(params);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "KPI导购员奖励积分.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询品牌奖励积分列表
    async getQueryBrandScoreList() {
      try {
        this.ScoreBrandInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await GuideAjax.getQueryBrandScoreList(this.ScoreBrandInfo);
        this.ScoreBrandList = list;
        this.ScoreBrandTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出品牌奖励积分列表
    async getExportBrandScoreList() {
      try {
        const data = await GuideAjax.getExportBrandScoreList(
          this.ScoreBrandInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "KPI品牌奖励积分.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryScoreList();
    this.getQueryBrandScoreList();
  },
  mounted() {},
};
</script>

<style lang="scss">
.Integral_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .secondsKill_table {
    margin-top: 20px;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
