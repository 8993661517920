var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Integral_wrapper" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.tabName,
            callback: function($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "导购员积分", name: "1" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "mr10",
                      attrs: { placeholder: "奖励类型", clearable: "" },
                      model: {
                        value: _vm.ScoreInfo.type,
                        callback: function($$v) {
                          _vm.$set(_vm.ScoreInfo, "type", $$v)
                        },
                        expression: "ScoreInfo.type"
                      }
                    },
                    _vm._l(_vm.bonusTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入手机号搜索" },
                    model: {
                      value: _vm.ScoreInfo.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.ScoreInfo, "mobile", $$v)
                      },
                      expression: "ScoreInfo.mobile"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入姓名搜索" },
                    model: {
                      value: _vm.ScoreInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.ScoreInfo, "name", $$v)
                      },
                      expression: "ScoreInfo.name"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入用户ID" },
                    model: {
                      value: _vm.ScoreInfo.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.ScoreInfo, "userId", $$v)
                      },
                      expression: "ScoreInfo.userId"
                    }
                  }),
                  _c("el-date-picker", {
                    staticClass: "ml10",
                    staticStyle: { width: "400px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "执行开始时间",
                      "end-placeholder": "执行结束时间"
                    },
                    model: {
                      value: _vm.ScoreInfo.dateRange,
                      callback: function($$v) {
                        _vm.$set(_vm.ScoreInfo, "dateRange", $$v)
                      },
                      expression: "ScoreInfo.dateRange"
                    }
                  }),
                  _c(
                    "el-select",
                    {
                      staticClass: "ml10",
                      attrs: { clearable: "", placeholder: "请选择导购员状态" },
                      model: {
                        value: _vm.ScoreInfo.userStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ScoreInfo, "userStatus", $$v)
                        },
                        expression: "ScoreInfo.userStatus"
                      }
                    },
                    _vm._l(_vm.errorOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.exportScoreData }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "secondsKill_table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.ScoreList, border: "", stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "userId", label: "用户ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "姓名" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "mobile", label: "手机号码" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "brandName", label: "所属品牌" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "shopName", label: "所属门店" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "createTime", label: "注册时间" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bonusType", label: "奖励类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.bonusType == "view"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("浏览")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "fission"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("裂变")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "enrollment"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("报名")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "card"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("售券")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "live_view"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("进直播间客户")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "live_order_cus"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播下订")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "live_self_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播自签单")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "live_other_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播贡献单")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "live_self_order_change"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播自签转单")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "live_other_order_change"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播贡献转单")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "process_self_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("过程自签单")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "process_devote_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("过程贡献单")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "seckill_user"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("秒杀客户")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "thumb"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("集赞客户")
                                ])
                              : _vm._e(),
                            scope.row.bonusType == "appointment"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播预约")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "scoreAmount", label: "奖励积分" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "userStatus",
                      align: "center",
                      label: "导购员状态"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.userStatus == "normal"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("正常")
                                ])
                              : _vm._e(),
                            scope.row.userStatus == "second"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("超出每秒预设的阈值")
                                ])
                              : _vm._e(),
                            scope.row.userStatus == "hour"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("超出每小时预设的阈值")
                                ])
                              : _vm._e(),
                            scope.row.userStatus == "day"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("超出每天预设的阈值")
                                ])
                              : _vm._e(),
                            scope.row.userStatus == "qcloud"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("腾讯云判断异常")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "implementTime", label: "执行时间" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "scoreAvailableAmount", label: "积分总数" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c("div"),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.ScoreInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.ScoreTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "品牌积分", name: "2" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入品牌名称" },
                    model: {
                      value: _vm.ScoreBrandInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.ScoreBrandInfo, "name", $$v)
                      },
                      expression: "ScoreBrandInfo.name"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage2 }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.getExportBrandScoreList }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "secondsKill_table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.ScoreBrandList, border: "", stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "brandId", label: "品牌ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "品牌名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "scoreAmount", label: "积分奖惩" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "remark", label: "奖惩原因" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "implementTime", label: "执行时间" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "scoreAvailableAmount", label: "剩余积分数" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c("div"),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.ScoreBrandInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.ScoreBrandTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange2 }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }